import React from 'react';
import styles from './Widget.module.scss';
import World from '../world/World';
import Number from '../number/Number';
import StatusSet from '../status-set/StatusSet';
import SocialMediaFeed from '../social-media-feed/SocialMediaFeed';
import SentryErrorList from '../sentry-error-list/SentryErrorList';

const Widget = ({ id, name, type, config, data, preview }) => {
	let infoDisplay = null;
	if (type === 'number')
		infoDisplay = <Number {...{ data, config, preview }} />;
	if (type === 'world')
		infoDisplay = (
			<div className={styles.world}>
				<World />
			</div>
		);
	if (type === 'status-set')
		infoDisplay = <StatusSet {...{ data, config, preview }} />;
	if (type === 'social-media-feed')
		infoDisplay = <SocialMediaFeed {...{ data, config, preview }} />;
	if (type === 'sentry-error-list')
		infoDisplay = <SentryErrorList {...{ data, config, preview }} />;

	console.log({ preview });

	const widgetClassName = preview
		? [styles.widget, styles['widget-preview']].join(' ')
		: styles.widget;

	return (
		<div
			className={widgetClassName}
			id={`widget-${id}`}
			style={config.gridSettings}
		>
			<div className={styles['widget-title']}>{name}</div>
			{infoDisplay}
		</div>
	);
};

export default Widget;
