import React from 'react';
import styles from './SocialMediaFeed.module.scss';

const Avatar = () => <div className={styles['feed-item-avatar']}>PJ</div>;

const FeedItem = ({ id, username, content, timestamp }) => (
	<div className={styles['feed-item']} key={id}>
		<div className={styles['feed-item-user']}>
			<Avatar /> {username}
		</div>
		<div className={styles['feed-item-content']}>{content}</div>
		<div className={styles['feed-item-timestamp']}>{timestamp}</div>
	</div>
);

const SocialMediaFeed = ({ data, preview }) => {
	const widgetClassName = preview
		? [styles['social-media-feed'], styles['widget-preview']].join(' ')
		: styles['social-media-feed'];

	return <div className={widgetClassName}>{data.list.map(FeedItem)}</div>;
};

export default SocialMediaFeed;
