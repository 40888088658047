import React from 'react';
import styles from './SentryErrorList.module.scss';

const Row = ({ timestamp, error, occurrences }) => (
	<tr>
		<td>{timestamp}</td>
		<td>{error}</td>
		<td>{occurrences}</td>
	</tr>
);

const Table = ({ list }) => (
	<table>
		<thead>
			<tr>
				<th>Time ago</th>
				<th>Error</th>
				<th>Occurrences</th>
			</tr>
		</thead>
		<tbody>{list.map(Row)}</tbody>
	</table>
);

const SentryErrorList = ({ data, preview }) => {
	const widgetClassName = preview
		? [styles['sentry-error-list'], styles['widget-preview']].join(' ')
		: styles['sentry-error-list'];

	return (
		<div className={widgetClassName}>
			<Table list={data.list} />
		</div>
	);
};

export default SentryErrorList;
