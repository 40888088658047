import React from 'react';

const Percentage = ({ number }) => (
	<span>
		{number.toLocaleString()}
		<small>%</small>
	</span>
);

const Milliseconds = ({ number }) => (
	<span>
		{number}
		<small>ms</small>
	</span>
);

const MinutesAndSeconds = ({ minutes, seconds }) => (
	<span>
		{minutes}
		<small>m</small>
		{seconds}
		<small>s</small>
	</span>
);

const formats = {
	number: (number) => number.toLocaleString(),
	currency: (number) => '$ ' + number.toLocaleString(),
	minutesAndSeconds: (number) => {
		const minutes = Math.floor(number / 60);
		const seconds = number - minutes * 60;
		return <MinutesAndSeconds minutes={minutes} seconds={seconds} />;
	},
	milliseconds: (number) => <Milliseconds number={number} />,
	percentage: (number) => <Percentage number={number} />,
};

export default formats;
