import React from 'react';
import styles from './Dashboard.module.scss';
import Widget from '../widget/Widget';

const Dashboard = ({ loading, error, widgets, preview }) => {
	if (loading)
		return (
			<div id={styles.dashboard}>
				<div id={styles['loading-status']}>Loading...</div>
			</div>
		);
	if (error)
		return (
			<div id={styles.dashboard}>
				<div id={styles['error-status']}>Error :(</div>
			</div>
		);
	return (
		<div
			id={styles.dashboard}
			className={preview && styles['dashboard-preview-mode']}
		>
			{widgets.map((w) => (
				<Widget {...w} key={w.id} preview={preview} />
			))}
		</div>
	);
};

export default Dashboard;
