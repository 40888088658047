import React from 'react';
import styles from './Number.module.scss';
import formats from '../helpers/formats';

const Number = ({ data, config, preview }) => {
	if (!data || !data.value) return null;
	let changeClassName = 'change';
	let changeDirection = null;
	let value = data.value;
	let change = data.change;
	if (config.changeType === 'normal') {
		if (data.change > 0)
			changeClassName = [styles.change, styles.positive].join(' ');
		if (data.change < 0)
			changeClassName = [styles.change, styles.negative].join(' ');
	} else {
		if (data.change > 0)
			changeClassName = [styles.change, styles.negative].join(' ');
		if (data.change < 0)
			changeClassName = [styles.change, styles.positive].join(' ');
	}

	if (data.change > 0) changeDirection = '▴';
	if (data.change < 0) changeDirection = '▾';
	if (config.format) {
		value = value && formats[config.format](data.value);
		change = change && formats[config.format](data.change);
	}

	const widgetClassName = preview
		? [styles.number, styles['widget-preview']].join(' ')
		: styles.number;

	return (
		<div className={widgetClassName}>
			<div className={styles.value}>{value}</div>
			<div className={changeClassName}>
				{changeDirection} {change}
			</div>
		</div>
	);
};

export default Number;
