import React from 'react';
import styles from './StatusSet.module.scss';

const statusIcons = {
	green: <div className={[styles.status, styles.green].join(' ')}></div>,
	yellow: <div className={[styles.status, styles.yellow].join(' ')}></div>,
	red: <div className={[styles.status, styles.red].join(' ')}></div>,
};

const Row = ({ id, service, status }) => (
	<tr key={id}>
		<td>{service}</td>
		<td className={styles['status-row']}>{statusIcons[status]}</td>
	</tr>
);

const Table = ({ list }) => (
	<table>
		<thead>
			<tr>
				<th>Service</th>
				<th>Status</th>
			</tr>
		</thead>
		<tbody>{list.map(Row)}</tbody>
	</table>
);

const StatusSet = ({ data, preview }) => {
	const listOne = data.list.slice(0, 6);
	const listTwo = data.list.slice(6, 12);
	const listThree = data.list.slice(12, 18);

	const widgetClassName = preview
		? [styles['status-set'], styles['widget-preview']].join(' ')
		: styles['status-set'];

	return (
		<div className={widgetClassName}>
			<Table list={listOne} />
			<Table list={listTwo} />
			<Table list={listThree} />
		</div>
	);
};

export default StatusSet;
